import React from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap'
import ContactForm from "./ContactForm";

const Footer: React.FC = () => {
    return (
        <Container fluid className="pt-3 bg-primary justify-content-center">
            <Row className="text-center text-black">
                <h1>Contact Me</h1>
            </Row>

            <Row className="justify-content-center col-lg-6 col-md-8 col-sm-11 mx-auto">
                <ContactForm/>
            </Row>

            <Row className="bg-white text-black align-items-center px-5 py-2">
                <Col className="d-flex justify-content-center align-items-center flex-wrap gap-3">
                    <p className="mb-0">Adria Stubbs | 07392 155 932 | adriastubbscounselling@gmail.com</p>
                    <span className="d-none d-sm-inline">•</span>
                    <a href="/privacy" className="text-dark">Privacy Statement</a>
                </Col>
            </Row>
        </Container>

    )
}


export default Footer;