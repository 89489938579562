import React, {Fragment} from 'react';
import '../styles/styles.css';
import Container from "react-bootstrap/Container";
import {Image, Col, Row, Table, Accordion, ListGroup} from "react-bootstrap";
import Footer from "../components/Footer";

const MyPractise: React.FC = () => {
    return (
        <Fragment>
            <Container className="pebble-background" fluid>

                <Row className="justify-content-center">
                    <Col xs={10} md={8} lg={6}
                         className="my-4 bg-primary rounded-5 p-3 text-light text-body-emphasis">
                        <Row className="align-items-center text-center">

                            <h1 className="quicksand-bold">About Counselling</h1>
                            <p>
                                At the heart of all
                                good
                                counselling is the relationship between the counsellor and the client. It is
                                this relationship which determines the success or otherwise of the
                                therapy.
                                Being heard and having a space to focus on personal needs often produces a sense of
                                calm. Over time coming into contact, engaging with and making
                                sense of
                                our emotions can help clients feel more understanding of themselves. This can help with
                                feelings of fear, anger,
                                denial, guilt and shame. The way in which we take responsibility in
                                relationship
                                often changes as a result of this work.
                            </p>
                        </Row>
                        <Row className="justify-content-center text-center">
                            <Image src="images/trees.jpg" className="m-3 rounded-4" style={{maxWidth: '500px'}}></Image>
                        </Row>

                        <p className="align-items-center text-center">

                            Many people come to describe counselling as an important personal investment. It can
                            bring positive benefits to them, their relationships and life generally. Clients often
                            know when counselling is working because they feel a change in themselves.


                            You may need just a few sessions to get you through or you may feel the need to come to
                            counselling for some time before things feel different. I am open to and comfortable
                            with both possibilities.
                        </p>
                        <Row className="align-items-center text-center">

                            <p>
                                If clients decide not to continue it is important to have an ending session to look at
                                what has been worked on, talk about what it feels like to end and think about support going forward.</p>
                            <p>
                                In line with good professional practice, I do not give advice, although advice is often
                                sought. If you are looking for advice it may be that the process of talking this through
                                is of benefit.

                                I believe that my clients are uniquely positioned to understand themselves and their own
                                needs and that with the right support and understanding they can come to feel empowered
                                and grow. Along the way I have faith in my clients knowing what they are - and may not
                                be - ready to talk about.
                            </p>
                        </Row>
                        <Row className="justify-content-center text-center">
                            <Image src="images/scottish_sky.jpg" className="m-3 rounded-4"
                                   style={{maxWidth: '500px'}}></Image>
                        </Row>
                    </Col>
                </Row>
                {/*<Row className="justify-content-center">*/}
                {/*    <Col xs={10} md={8} lg={6}*/}
                {/*         className="p-3 bg-light rounded-5 text-light text-body-emphasis">*/}
                {/*        <Row className="align-items-center text-center">*/}


                {/*        </Row>*/}
                {/*        <Row className="justify-content-center text-center">*/}
                {/*            <Image src="images/rainbow_in_gullane.jpg" className="m-3 rounded-4"*/}
                {/*                   style={{maxWidth: '500px'}}></Image>*/}
                {/*        </Row>*/}

                {/*    </Col>*/}

                {/*</Row>*/}
            </Container>

            <footer id="contact-form">
                <Footer/>
            </footer>
        </Fragment>

    )
}


export default MyPractise;
