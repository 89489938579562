import React from 'react';

import NavBar from "./components/navbar";
import Home from "./views/home";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import AboutMe from "./views/about-me";
import MyPractise from "./views/my-practise";
import Privacy from "./views/privacy";
import AboutCounselling from "./views/about-counselling";

function App() {
    return (
        <Router>
            <div className="background">
                <div className="App">
                    <NavBar/>
                    <Routes>
                        <Route path="/" element={<Home />}></Route>
                    </Routes>
                    <Routes>
                        <Route path="/about" element={<AboutMe />}></Route>
                    </Routes>
                    <Routes>
                        <Route path="/mypractise" element={<MyPractise />}></Route>
                    </Routes>
                    <Routes>
                        <Route path="/aboutcounselling" element={<AboutCounselling />}></Route>
                    </Routes>
                    <Routes>
                        <Route path="/privacy" element={<Privacy />}></Route>
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
