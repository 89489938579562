import React, {Fragment} from 'react';
import '../styles/styles.css';
import Container from "react-bootstrap/Container";
import {Image, Col, Row, Table, Accordion, ListGroup} from "react-bootstrap";
import Footer from "../components/Footer";

const MyPractise: React.FC = () => {
    return (
        <Fragment>
            <Container className="pebble-background m-0" fluid>
                <Row className="justify-content-center">
                    <Col xs={10} md={8} lg={6}
                         className="bg-light my-4 rounded text-center text-black justify-content-center align-content-center align-items-center">

                        <h1 className="quicksand-bold py-2 pt-3">My Practice</h1>
                        {/*<Row className="justify-content-center">*/}

                        {/*    <Image src="images/aberlady_beach.jpg" className="my-5" style={{*/}
                        {/*        maxWidth:*/}
                        {/*            '250px'*/}
                        {/*    }} roundedCircle></Image>*/}
                        {/*</Row>*/}
                        <p>
                            I currently offer counselling sessions in person in Edinburgh and East Lothian and online
                            when required.
                        </p>

                        <p className="m-lg-3 py-2">
                            I am committed to building a safe and trusted connection with my clients so that they can
                            explore freely what it is that's troubling them. I aim to offer a non-judgemental and
                            empathic
                            space where I am genuine and where clients feel accepted. I use my capacity for creativity
                            and play to help my clients find their own individual
                            solutions
                            to their difficulties
                        </p>

                        <Row className="text-start text-dark bg-primary py-2 p-lg-4 mb-3 m-2 rounded">
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>One-on-one Therapy</Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            I offer traditional talking therapy, employing creative
                                            approaches if helpful. The way I work depends on the unique needs and style
                                            of each
                                            client. I
                                            believe that every individual is uniquely placed to know what they feel,
                                            need
                                            and want and I see my role as supporting them in deepening this
                                            understanding in
                                            whatever way feels right.
                                        </p>
                                        <p>

                                            It is often said that it is the relationship that heals and this is widely
                                            supported by research. I pay attention to the counselling relationship,
                                            offering
                                            empathy without judgement and emphasising safety. I will let you know how I
                                            hear
                                            what you say, offer my own reflections and may occasionally challenge your
                                            thinking in the knowledge that sensitively delivered, constructive feedback
                                            can help clients feel less isolated, stuck, and alone with their problems.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Couples/Relationship Therapy</Accordion.Header>
                                    <Accordion.Body>
                                        <p> Couples therapy (the word couple meaning two people in a close relationship
                                            irrespective of its nature) can be helpful in increasing a couples’
                                            awareness of their relational dynamics. Over time ordinary day-to-day things
                                            like work, money and sex may have become fought over and other behaviours
                                            like intimate contact that hasn't been agreed upon outside of the core
                                            relationship or the
                                            withdrawal of contact or communication can undermine the intimacy and trust
                                            required for a fully functioning core relationship. </p>
                                        <p className="fs-6">The goal in couples therapy is to open up a space for
                                            communication and connection with a view to greater understanding, respect
                                            and decision-making. As part of the process it can be helpful to look at
                                            imbalances that may have developed in the couple relationship and see if,
                                            whether or where there is energy for re-establishing or establishing
                                            balance. My own mixed background (race, class, sexuality, faith and
                                            nationality) supports my understanding of the challenges and complexities of
                                            cross-cultural connection and conflict in all its forms. I am open to
                                            working with the full diversity of issues that couples may bring.</p>
                                        <p>I often time limit this type of therapy, the timescales being determined by the needs of each unique couple.</p>


                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        Systemic Constellations
                                    </Accordion.Header>
                                    <Accordion.Body>

                                        <p>
                                            Systemic constellations recognises that individuals are not isolated but
                                            part of
                                            a larger
                                            system
                                            such as a family, organisation or community. A relatively new approach to
                                            the
                                            counselling
                                            world,
                                            it can be a powerful tool for working through difficult issues.</p>
                                        <p>Originally
                                            developed by Bert
                                            Hellinger, a German psychotherapist, constellations looks at systemic
                                            (family)
                                            experience
                                            and
                                            helps to illuminate some of the deeper forces unknowingly influencing
                                            thought,
                                            behaviour and
                                            emotions. This is generally known as inter-generational trauma.</p>
                                        <p>This exploration of ‘what is’ often creates new ways of
                                            understanding
                                            and a sense
                                            of
                                            disentanglement which allows for new choices to be made. It aims to provide
                                            movement in
                                            places
                                            that might previously have felt stuck.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Outdoor Sessions with Individuals</Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            Working with clients outdoors allows for the exploration of feeling through
                                            engagement with nature. This highly flexible form of therapy can involve a
                                            ‘walk
                                            and talk’ approach, following an agreed route along the coast or inland, or
                                            perhaps meeting and relating in one place. This work may be informed and/or
                                            effected by the weather. Clients need not be particularly fit.
                                        </p>
                                        <p>Traditional
                                            psychotherapeutic boundaries apply to this way of working (eg, time, cost,
                                            agreed place and focus) and we have an agreement around confidentiality
                                            should
                                            we meet people along the way. First or early sessions begin in the
                                            counselling
                                            room.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>
                                        Fees
                                    </Accordion.Header>
                                    <Accordion.Body className="text-start">
                                        <p>Initial sessions
                                            take
                                            place either online or in a traditional counselling room setting and allow
                                            us a
                                            sense of
                                            whether
                                            - or how - we might work together. I'll ask some standard questions and
                                            we'll
                                            talk
                                            generally
                                            about what brings you to counselling. You'll
                                            also
                                            be able to ask me questions if you need to.</p>
                                        <p>You may wish to use the
                                            contact
                                            form on this
                                            website to initiate the process or telephone me to discuss your needs
                                            though
                                            please note
                                            that
                                            the telephone number supplied on this website is only periodically checked
                                            for
                                            messages. </p>
                                        <p>We can discuss together how long we might initially contract to see
                                            each
                                            other for
                                            and
                                            periodically review our arrangement.
                                        </p>
                                        <p>Concessions may be available for
                                            people on
                                            low
                                            incomes. Payment by BACS is preferred where possible.</p>
                                        <Table striped bordered size="md" className="rounded justify-content-center">
                                            <thead>
                                            <tr>
                                                <th>Option</th>
                                                <th>Duration</th>
                                                <th>Price</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Initial Session (Individual)</td>
                                                <td>50 minutes</td>
                                                <td>£30</td>
                                            </tr>
                                            <tr>
                                                <td>Initial Session (Couples)</td>
                                                <td>60 minutes</td>
                                                <td>£50</td>
                                            </tr>
                                            <tr>
                                                <td>Indoor Weekly (Individual)</td>
                                                <td>50 minutes</td>
                                                <td>£60</td>
                                            </tr>

                                            <tr>
                                                <td>Outdoor (Individual)</td>
                                                <td>50 minutes</td>
                                                <td>£60</td>
                                            </tr>

                                            <tr>
                                                <td>Couples (Weekly/Fortnightly/Other)</td>
                                                <td>60 - 90 minutes</td>
                                                <td>£80 - £120</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4" className="text-start">
                                    <Accordion.Header>
                                        Location
                                    </Accordion.Header>
                                    <Accordion.Body>

                                        <ListGroup as="ul">
                                            <ListGroup.Item>Monday: East Lothian (Gullane) and online</ListGroup.Item>
                                            <ListGroup.Item>Tuesday: PF Edinburgh, Edinburgh West
                                                End and online</ListGroup.Item>
                                            <ListGroup.Item>Wednesday: Edinburgh West End and online</ListGroup.Item>
                                        </ListGroup>
                                    </Accordion.Body>
                                </Accordion.Item>
                               <Accordion.Item eventKey="4" className="text-start">
                                    <Accordion.Header>
                                        Accessibility
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        I work on the ground floor but there is a step at the entrance of both places where I work. Please let me know if this is likely to be difficult for you. Online sessions can be arranged if necessary.
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                        </Row>

                    </Col>

                </Row>

            </Container>

            <footer id="contact-form">
                <Footer/>
            </footer>
        </Fragment>

    )
}


export default MyPractise;

