import React, {Fragment} from 'react';
import '../styles/styles.css';
import Container from "react-bootstrap/Container";
import {Image, Col, Row} from "react-bootstrap";
import Footer from "../components/Footer";

const AboutMe: React.FC = () => {
    return (
        <Fragment>
            <Container className="pebble-background" fluid>
                <Row className="justify-content-center">
                    <Col xs={10} md={8} lg={6}
                         className="bg-primary rounded-5 p-3 my-4 text-black text-body-emphasis text-center">
                        <h1 className="quicksand-bold">About Me</h1>
                        <p>
                            I began my independent adult life by travelling from Hong Kong to London on a bicycle in aid
                            of charity. That youthful energy sustained me as I worked for a number of years in the
                            non-profit and private sectors in North and East Europe and South Asia before graduating as
                            a mature student in the early 90s in Politics at Bristol University. After this,
                            establishing and running my own gardening and holiday let businesses further broadened my
                            experience of working with people from all walks of life and two years of volunteering as a
                            Samaritan lead me to train more formally as a counsellor.
                        </p>
                        <p>I studied and graduated with merit in counselling at Edinburgh University where I learnt to
                            work with a rich combination of psychodynamic and person-centred perspectives. I have
                            continued to work in this way for some time. More recently I have moved towards working more
                            systemically which is to say I situate my clients within a broader family context and look
                            at the implications of this in terms of the issues my clients may be struggling with.</p>
                        <p>I have been a volunteer counsellor at Pf in Edinburgh for nine years and run my own busy
                            private practice for eight years.</p>

                        <Row className="justify-content-center d-flex"> <Image className="w-75 m-3" rounded
                                                                               src="images/north_berwick_beach.jpg"
                                                                               alt={"North Berwick Beach"}></Image>
                        </Row>
                        <p>As a counsellor I have worked with people who have difficulties with relationship, loss,
                            anxiety, depression,
                            trauma, illness, faith, identity, self-esteem, learning, dissociation, sexuality,
                            racial
                            difference, bullying, abuse and neglect. I have supported people with feelings
                            of
                            hopelessness, powerlessness and confusion, people who self harm or have OCD. I welcome neurodiverse clients and clients who have or believe they may have ADHD. I remain
                            open
                            to working with a broad range of issues.</p>
                        <p>I take continuous professional development seriously and have attended workshops and seminars
                            on
                            subjects such as attachment, fatherhood, dreams, play, autism, racial difference, sexuality
                            and
                            human trafficking.</p>
                        <p>I am a registered member of BACP (British Association for Counselling and Psychotherapy) and adhere to
                            BACP’s Ethical Framework (https://www.BACP.org.uk). I am fully insured with professional indemnity and public liability insurance.</p>
                    </Col>

                </Row>
                <Row className="justify-content-center">
                    <Col xs={10} md={8} lg={5} className="text-center">

                    </Col>
                </Row>
            </Container>
            <footer id="contact-form">
                <Footer/>
            </footer>
        </Fragment>
    )
}


export default AboutMe;

