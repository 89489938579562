import Container from 'react-bootstrap/Container';
import {Navbar, Nav} from 'react-bootstrap'
import '../styles/styles.css';

function NavBar() {
    return (
        <div>
            <Navbar expand="lg" className="bg-primary">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            src="/images/adria_stubbs_logo.png"
                            className="d-inline-block align-top"
                            height="50"
                            alt="Adria Stubbs Counselling Logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="d-flex gap-5 fs-5 px-5 align-content-center">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/mypractise">My Practice</Nav.Link>
                            <Nav.Link href="/about">About Me</Nav.Link>
                            <Nav.Link href="/aboutcounselling">About Counselling</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default NavBar;